<template>
  <footer class="bg-gray-200 py-8 dark:bg-gray-800">
    <div class="w-11/12 m-auto max-w-screen-2xl">
      <div class="xl:flex xl:flex-row xl:justify-around">
        <div
          class="
            flex flex-col
            justify-around
            items-center
            xl:mx-40 xl:w-full
            bg-hb
            text-white
            rounded-lg
            p-4
            m-auto
            max-w-md
          "
        >
          <h2 class="text-2xl">Contact <i class="fas fa-id-card-alt"></i></h2>
          <address class="my-8 not-italic">
            <ul
              class="flex flex-col justify-around items-start mb-4 text-white"
            >
              <li class="text-2xl">Vr'tig.0</li>
              <li class="group">
                <i class="fas fa-phone-alt group-hover:text-yellow-500 mr-1"></i> 
                <a
                  href="tel:0607884632"
                  class="underline group-hover:text-yellow-500"
                  >06 07 88 46 32</a
                >
              </li>
              <li class="group">
                <i class="fas fa-envelope group-hover:text-yellow-500 mr-1"></i> 
                <a
                  href="mailto:alain.chaix@vrtig0.fr"
                  class="underline group-hover:text-yellow-500"
                  >alain.chaix@vrtig0.fr</a
                >
              </li>
              <li class="group">
                <i
                  class="fas fa-map-marker-alt group-hover:text-yellow-500 mr-1"
                ></i> 
                <a
                  href="https://www.google.fr/maps/place/3+Rue+Yvan+Audouard+B%C3%A2timent+E107,+13400+Arles/@43.6734367,4.636591,17z/data=!3m1!4b1!4m5!3m4!1s0x12b67673e66f2afb:0xb7da5c06ab985cb4!8m2!3d43.6734328!4d4.6387797"
                  class="underline group-hover:text-yellow-500"
                  target="_blank"
                  rel="noopener"
                  >3 rue Yvan Audouard Bâtiment E107 - 13520 Arles</a
                >
              </li>
            </ul>
          </address>
        </div>
        <div>
          <img
            src="../assets/img_partenaires.png"
            alt="partner : airbus, CFAI Istres, EDF, team henri fabre, aix marseille université greta"
            class="
              xl:w-10/12 xl:h-full
              object-fill
              max-w-screen-lg
              w-full
              lg:w-8/12
              m-auto
              xl:-ml-8
            "
          />
        </div>
      </div>

      <div class="flex justify-around mt-8">
        <a
          href="https://www.facebook.com/Vrtig0-100177604847560"
          target="_blank"
          class="rounded-full clickmesocial"
          aria-label="Voir notre page facebook"
        >
          <div
            class="
              h-16
              w-16
              border
              flex
              justify-center
              items-center
              rounded-full
              text-white dark:border-gray-700
              bg-fb
              text-4xl
            "
          >
            <i class="fab fa-facebook-f"></i>
          </div>
        </a>
        <a
          href="https://www.instagram.com/vrtig.0"
          target="_blank"
          class="rounded-full clickmesocial"
          aria-label="Voir notre page instagram"
        >
          <div
            class="
              h-16
              w-16
              border
              flex
              justify-center
              items-center
              rounded-full
              text-white dark:border-gray-700
              bg-insta
              text-4xl
            "
          >
            <i class="fab fa-instagram"></i>
          </div>
        </a>
        <a
          href="https://www.linkedin.com/in/alain-chaix-72027b34/"
          target="_blank"
          class="rounded-full clickmesocial"
          aria-label="Voir notre page linked in"
        >
          <div
            class="
              h-16
              w-16
              border
              flex
              justify-center
              items-center
              rounded-full
              text-white dark:border-gray-700
              bg-linkedin
              text-4xl
            "
          >
            <i class="fab fa-linkedin-in"></i>
          </div>
        </a>
        <a
          href="mailto:alain.chaix@vrtig0.fr"
          class="rounded-full clickmesocial"
          aria-label="Nous envoyer un mail"
        >
          <div
            class="
              h-16
              w-16
              border
              flex
              justify-center
              items-center
              rounded-full
              text-white dark:border-gray-700
              bg-red-500
              text-4xl
            "
          >
            <i class="far fa-envelope"></i>
          </div>
        </a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "footer",
};
</script>

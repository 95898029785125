import { createStore } from "vuex";

export default createStore({
  state: {
    darkMode: false,
  },
  mutations: {
    updateDM(state, boolean){
      state.darkMode = boolean.value;
    },
  },
  getters: {},
  actions: {},
  modules:{},
})
<template>
  <nav
    id="navmobile"
    class=" flex flex-col justify-center items-center top-0 right-0 h-full w-5/12 bg-hb z-30 space-y-8 fixed">
    <router-link
      @click="showNavMobile"
      to="/"
      class=" text-white border border-white py-2 px-4 w-11/12 text-center rounded active:bg-white active:text-gray-900">{{ $t("header.home") }} <i class="fas fa-home ml-2"></i
    ></router-link>
    <router-link
      @click="showNavMobile"
      to="/projects"
      class=" text-white border border-white py-2 px-4 w-11/12 text-center rounded active:bg-white active:text-gray-900">{{ $t("header.projects") }} <i class="fab fa-unity ml-2"></i
    ></router-link>
    <LocalSwitcher class="absolute bottom-0 " />
  </nav>
</template>
<script>
import { useI18n } from "vue-i18n";
import LocalSwitcher from "./LocalSwitcher.vue";

export default {
  name: "",
  components: {
    LocalSwitcher,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });
    return { t };
  },
  methods: {
    showNavMobile() {
      const navMobile = document.querySelector("#navmobile");
      const icoNavMobile = document.querySelector('#activeNavMobile');
      if (navMobile.classList.contains("hidden")) {
        navMobile.classList.remove("hidden");
      } else {
        icoNavMobile.classList.remove('animEnableNavMobile');
        icoNavMobile.classList.add('animDisableNavMobile');
        navMobile.classList.add("hidden");
      }
    },
  },
};
</script>
<style lang="scss">
.transitionOutNavMobile {
  animation: transitionOutNavMobile 0.8s ease both;
}
@keyframes transitionOutNavMobile {
  0% {
    transform: translateX(0rem);
    opacity:1;
  }
  100% {
    transform: translateX(100%);
    opacity:0;
  }
}
.transition_navmobile {
  animation: transitionnavmobile 0.8s ease 0s both;
}
@keyframes transitionnavmobile {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0rem);
    opacity: 1;
  }
}
</style>
<template>
  <section
    class="
      m-auto
      w-12/12
      flex flex-col
      justify-center
      items-center
      py-8
      xl:py-8
      p-4
      shadow-lg bg-gray-100 dark:bg-gray-800 dark:text-white
      relative
    "
  >
    <svg class="absolute right-0 -top-20 h-40 w-full text-[#F3F4F6] dark:text-gray-800" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
      <polygon points="0,50 0,100 100,50 100,0" />
    </svg>

    <h2 class="text-2xl my-4 text-center opacity-0">
      {{ $t("sectiontwo.h2") }}
    </h2>
    <div class="barsection h-1 w-8/12 bg-hb rounded-2xl dark:bg-white"></div>
    <div class="xl:flex lg:w-11/12 max-w-screen-2xl">
      <div
        class="
          flex flex-col
          justify-center
          items-center
          m-auto
          xl:w-4/12 xl:mx-4 xl:justify-start
        "
      >
        <h3 class="my-4 mt-16 text-xl opacity-0">
          {{ $t("sectiontwo.h3.formations") }}
        </h3>
        <p
          class="text-justify opacity-0"
          v-html="$t('sectiontwo.text.formations')"
        ></p>
      </div>
      <div
        class="
          flex flex-col
          justify-center
          items-center
          m-auto
          xl:w-4/12 xl:mx-4 xl:justify-start
        "
      >
        <h3 class="my-4 mt-16 text-xl opacity-0">
          {{ $t("sectiontwo.h3.bureau") }}
        </h3>
        <p
          class="text-justify opacity-0"
          v-html="$t('sectiontwo.text.bureau')"
        ></p>
        <iframe
          src="https://www.youtube.com/embed/37j3c1xujJI"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="h-80 w-full opacity-0 z-10"
        ></iframe>
      </div>
      <div
        class="
          flex flex-col
          justify-center
          items-center
          m-auto
          xl:w-4/12 xl:mx-4 xl:justify-start
        "
      >
        <h3 class="my-4 mt-16 text-xl opacity-0">
          {{ $t("sectiontwo.h3.maintenance") }}
        </h3>
        <p
          class="text-justify opacity-0"
          v-html="$t('sectiontwo.text.maintenance')"
        ></p>
        <img
          src="../assets/img_di_maintenance.jpeg"
          alt="image illustrant une maintenance"
          class="sm:h-60 object-cover sm:w-full my-4 rounded-xl opacity-0 z-10"
        />
      </div>
    </div>
    <svg class="absolute right-0 z-0 -bottom-20 h-40 w-full text-[#F3F4F6] dark:text-gray-800" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
      <polygon points="0,50 0,100 100,50 100,0" />
    </svg>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "sectionone",
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

     

    return { t };
  },
};
</script>

<template>
  <div class="spawn_animation">
    <Herobanner />
    <main id="mainIndex" class="transition duration-300">
      <Sectionone class="transition-colors duration-300 pt-20"/>
      <Sectiontwo class="transition-colors duration-300"/>
      <Sectionthree class="transition-colors duration-300"/>
    </main>
  </div>
</template>

<script>
import Herobanner from "../components/herobanner.vue";
import Sectiontwo from "../components/sectiontwo.vue";
import Sectionone from "../components/sectionone.vue";
import Sectionthree from "../components/sectionthree.vue";
import { useI18n } from "vue-i18n";

export default {
  name: "Home",
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

     

    return { t };
  },
  components: {
    Herobanner,
    Sectiontwo,
    Sectionone,
    Sectionthree,
  },
  methods: {
    scrollingEffect() {
      var elements;
      var windowHeight;

      function init() {
        elements = document.querySelectorAll(".opacity-0");
        windowHeight = window.innerHeight;
      }

      function checkPosition() {
        for (var i = 0; i < elements.length; i++) {
          var element = elements[i];
          var positionFromTop = elements[i].getBoundingClientRect().top;

          if (positionFromTop - windowHeight <= -180) {
            element.classList.add("transition_top");
            element.classList.remove("opacity-0");
          }
        }
      }
      init();
      checkPosition();
      window.addEventListener("scroll", checkPosition);
      window.addEventListener("scroll", init);
    },
  },
  beforeMount() {
    this.scrollingEffect();
  },
};
</script>
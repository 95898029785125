<template>
  <div class="flex z-10">
    <button type="button" aria-label="Pass the text in English" id="ENLang" @click="switchLocalei18n('en')" class="btn-LS"
    >
      EN
    </button>
    <button type="button" aria-label="Passer le texte en Français" id="FRLang" @click="switchLocalei18n('fr')" class="btn-LS"
    >
      FR
    </button>
    <button type="button" aria-label="Activer/désactiver le mode sombre" @click="enableDM" class="btn-LS"
    >
      <i class="fas fa-moon"></i>
    </button>
  </div>
</template>

<script>
import store from "../store/index";
export default {
  name: "LocalSwitcher",
  methods: {
    switchLocalei18n(locale) {
      localStorage.setItem("lang", locale);
      this.$i18n.locale = locale;
      if(locale == "en") {
        document.title = "Vr'tig.0 - Production studio for real-time 3D applications (VR)";
      }
      if(locale == "fr") {
        document.title = "Vr'tig.0 - Studio de production d’applications 3D en temps réel (VR)";
      }
    },
    enableDM() {
      let appDOM = document.querySelector('#app');
      if(appDOM.classList.contains("dark")){
        appDOM.classList.remove("dark");
        store.commit('updateDM', {value: false})
        localStorage.setItem("darkMode", false);
      } else {
        appDOM.classList.add('dark');
        store.commit('updateDM', {value: true})
        localStorage.setItem("darkMode", true);
      }
    },
    launchDarkModeAndLangPref() {
      this.langPref();
      this.darkModePref();
    },
    langPref(){
      let langLS = localStorage.getItem("lang");
      if (langLS == "fr" || navigator.language == "fr-FR" || navigator.language == "fr" || navigator.language == "fr-CA" || navigator.language == "fr-CH"
      ) {
        this.switchLocalei18n("fr");
      } else {
        this.switchLocalei18n("en");
      }
      if (langLS == "en") {
        this.switchLocalei18n("en");
      }
    },
    darkModePref(){
      let appDOM = document.querySelector('#app');
      let darkModeLS = localStorage.getItem("darkMode");
      if(darkModeLS == "true"){
        appDOM.classList.add('dark');
        store.commit('updateDM', {value: true})
      } else {
        appDOM.classList.remove('dark');
        store.commit('updateDM', {value: false})
      }
      if(!darkModeLS){
        localStorage.setItem("darkMode", false);
      }
    },
  },
  beforeMount(){
    this.launchDarkModeAndLangPref();
  }
};
</script>
<style lang="scss" scoped>
#FRLang {
  &:active {
    background-color: rgb(0, 132, 255);
  }
}
#ENLang {
  &:active {
    background-color: rgb(160, 40, 40);
  }
}
.btn-LS {
  @apply shadow-md w-8 h-8 flex justify-center items-center px-3 py-1 rounded m-1 border border-white text-white hover:bg-white hover:text-gray-900 focus:bg-white focus:text-gray-900
shadow-md w-8 h-8 flex justify-center items-center px-3 py-1 rounded m-1 border border-white text-white hover:bg-white hover:text-gray-900 focus:bg-white focus:text-gray-900;
}
</style>

<template>
  <Header />
  <router-view />
  <Footer />
</template>

<script>
import Footer from "./components/footer.vue";
import Header from "./components/header.vue";

export default {
  components: {
    Footer,
    Header,
  },
};
</script>

<style lang="scss">
$bg-hb:rgb(34, 54, 90);
body::-webkit-scrollbar {
  width: 8px;
  &:hover {
    width: 12px;
  }
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: $bg-hb;
}
.transition_top {
  animation: transitiontopY 1s ease-out 0s both;
}
@keyframes transitiontopY {
  0% {
    transform: translateY(2rem);
    opacity: 0;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}
iframe {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 0.2rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
}

h1,
h2 {
  font-family: "Roboto Slab", Arial, Helvetica, sans-serif;
}

ul {
  list-style-type: none;
}

.bg-hb {
  background-color: #0b1c3a;
}

.bg-active:active {
  transform: scale(0.9);
}
.clickmesocial:hover {
  animation: clickmesocial 0.4s ease 0s alternate infinite forwards;
}
@keyframes clickmesocial {
  0% {
    transform: translateY(0.1rem) translateX(-0.1rem);
  }
  25% {
    transform: translateX(0.1rem) translateY(0.1rem);
  }
  50% {
    transform: translateY(-0.1rem) translateX(-0.1rem);
  }
  75% {
    transform: translateX(0.1rem) translateY(-0.1rem);
  }
  100% {
    transform: translateY(0rem) translateX(0rem);
  }
}
.bg-fb {
  background-color: #3b5998;
}

.bg-insta {
  background: linear-gradient(
    to bottom left,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d,
    #f56040,
    #f77737,
    #fcaf45,
    #ffdc80
  );
}

.bg-linkedin {
  background-color: #0077b5;
}

.bg-grad-gray {
  background: linear-gradient(to right bottom, #fdfdfd 0%, #cacaca 74%);
}

.bg-grad-gray-top {
  background: linear-gradient(to bottom, #ffffff 20%, #e9e9e9 100%);
}
.bg-grad-blue {
  background: linear-gradient(to right, #3eadcf, #abe9cd);
}
.bg-grad-yellow {
  background: linear-gradient(to right, #eccc68, #d3d3d3);
}

.bg-grad-SC {
  background: linear-gradient(to right bottom, #ffffff 25%, #2ccff8);
}

.gradientborderyellow {
  display: flex;
  align-items: center;
  position: relative;
  background: white;
  border: 2px solid transparent;
  box-sizing: border-box;
  background-clip: padding-box;
}
.gradientborderyellow:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, #eccc68, #d3d3d3);
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
}

.gradientborderblue {
  display: flex;
  align-items: center;
  position: relative;
  background: white;
  border: 2px solid transparent;
  box-sizing: border-box;
  background-clip: padding-box;
}
.gradientborderblue:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, #3eadcf, #abe9cd);
  z-index: -100;
  margin: -2px;
  border-radius: inherit;
}
.gradientborderblue:after:hover {
  z-index: inherit;
}

.gradientborderSC {
  display: flex;
  align-items: center;
  position: relative;
  background: white;
  border: 2px solid transparent;
  box-sizing: border-box;
  background-clip: padding-box;
}
.gradientborderSC:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom right, #ffffff 30%, #2ccff8);
  z-index: -100;
  margin: -2px;
  border-radius: inherit;
}
.gradientborderSC:after:hover {
  z-index: inherit;
}
.transitiontopY {
  animation: transitiontopY 0.9s;
  transform-origin: bottom;
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  animation-fill-mode: backwards;
}
@keyframes transitiontopY {
  0% {
    transform: translateY(5rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}
.slogan {
  font-family: "Indie Flower", Arial, Helvetica, sans-serif;
  color: #8eafe9;
}

section:hover > .barsection {
  transform: scaleX(1);
  transform-origin: left;
}

.barsection {
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 1s ease-in-out;
  transition-delay: 0s;
}
@media (max-width: 768px) {
  .barsection {
    transform: scaleX(1);
    animation: transitiontopY 0.9s;
  }
}

.carte {
  border-radius: 0.2rem;
  box-shadow: 1px 1px 5px #bbbbbb;
}
.carte:hover {
  cursor: pointer;
  transform: translateY(-0.8rem);
  transition: transform 0.3s ease-out;
  background: transparent;
  box-shadow: 0.2rem 0.2rem 1rem #bbbbbb;
}

main:hover > .barsection {
  transform: scaleX(1);
  transform-origin: left;
}

#project-hv2r-card > i:hover,
#project-hyvirea-card > i:hover,
#project-teamvr-card > i:hover,
#project-escapewatt-card > i:hover,
#project-inovsys-card > i:hover {
  cursor: pointer;
}
.spawn_animation {
  animation: spawn_animation 0.8s ease-out 0s normal both;
}
@keyframes spawn_animation {
  0%{
    transform: translateX(50%);
    opacity: 0;
  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
}
/*# sourceMappingURL=style.css.map */
</style>

<template>
  <div id="herobanner" class="flex flex-row justify-center items-center bg-hb pb-4 h-50vh transition-all duration-700 relative min-h-[310px]">
    <svg class="absolute right-0 -bottom-20 h-40 w-full text-[#0B1C3A]" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
      <polygon points="0,50 0,100 100,50 100,0" />
    </svg>
    <div
      class="
        m-auto
        w-11/12
        flex flex-col
        justify-center
        items-center
        lg:mt-0 lg:p-4 lg:w-6/12
        xl:w-6/12 xl:m-4
      "
    >
      <h2 class="my-4 text-3xl slogan">{{ $t("slogan") }}</h2>
      <p class="text-center text-white lg:text-xl" v-html="$t('intro')"></p>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "herobanner",
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

     

    return { t };
  },
  methods: {
    herobannerEffect() {
      window.addEventListener("scroll", function () {
        const herobanner = this.document.querySelector("#herobanner");
        herobanner.classList.toggle("heightAtScroll", window.scrollY > 50);
      });
    },
  },
  beforeMount() {
    this.herobannerEffect();
  }
};
</script>

<style scoped lang="scss">
.h-50vh {
  height: 50vh;
}
.heightAtScroll {
  height: 14rem;
  @media (max-width: 1024px) {
    height:50vh;
  }
}
</style>

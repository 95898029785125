<template>
  <section
    class="
      m-auto
      flex flex-col
      justify-center
      items-center
      py-8
      lg:w-full
      dark:bg-gray-900
      dark:text-white
      bg-white
    "
  >
    <h2 class="transitiontopY text-2xl my-4 text-center">
      {{ $t("sectionone.h2") }}
    </h2>
    <div class="barsection h-1 w-8/12 bg-hb rounded-2xl dark:bg-white"></div>
    <div class="flex flex-col xl:flex-row lg:w-10/12 max-w-screen-xl">
      <div
        class="
          flex flex-col
          justify-between
          items-center
          m-auto
          xl:mx-4 xl:h-auto xl:w-6/12
          p-4
          my-4
        "
      >
        <h3 class="my-4 text-xl opacity-0">{{ $t("sectionone.h3.pro") }}</h3>
        <img
          src="../assets/img_secteur_pro.jpg"
          alt="image illustrant le secteur professionnel"
          class="
            object-cover
            h-24
            w-full
            sm:h-40
            md:h-60
            xl:h-80
            rounded-xl
            my-4
            opacity-0
          "
        />
        <p
          class="  -pt-16 opacity-0"
          v-html="$t('sectionone.text.pro')"
        ></p>
      </div>
      <div
        class="
          flex flex-col
          justify-between
          items-center
          m-auto
          xl:mx-4 xl:h-auto xl:w-6/12
          p-4
          my-4
        "
      >
        <h3 class="my-4 text-xl opacity-0">{{ $t("sectionone.h3.sante") }}</h3>
        <p
          class="  opacity-0"
          v-html="$t('sectionone.text.sante')"
        ></p>
        <iframe
          src="https://www.youtube.com/embed/-ZUiF44gUGw"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="h-80 opacity-0"
        ></iframe>
      </div>
    </div>
    <p class="my-16 opacity-0 text-center" v-html="$t('sectionone.text.ligne')"></p>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "sectionone",
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

     

    return { t };
  },
};
</script>

<template>
  <section
    class=" m-auto flex flex-col justify-center items-center py-8 lg:w-full dark:bg-gray-900 dark:text-white pt-20 bg-white
    "
  >
    <h2 class="text-2xl my-4 opacity-0">{{ $t("sectionthree.h2") }}</h2>
    <div class="barsection h-1 w-8/12 bg-hb rounded-2xl dark:bg-white"></div>
    <div class="xl:flex xl:w-12/12 lg:w-10/12 max-w-screen-xl">
      <div
        class=" flex flex-col justify-center items-center m-auto xl:w-10/12 xl:mx-4 xl:justify-between p-4 my-4
        "
      >
        <h3 class="my-4 mt-16 text-2xl opacity-0">
          <a href="https://www.skincasts.fr/" target="_blank" rel="noopener" class="h-24 w-8/12 lg:w-6/12 sm:h-52 opacity-0">
            {{ $t("sectionthree.h3.skincasts") }}
          </a>
        </h3>
        <a href="https://www.skincasts.fr/" target="_blank" rel="noopener" class="w-8/12 lg:w-6/12 sm:h-52 opacity-0 text-blue-400 hover:text-black hover:underline">
          <img v-show="!$store.state.darkMode"
            src="../assets/logo_skincasts_noir.png"
            alt="skincasts"
            class="h-full w-full object-contain"
          />
          <img v-show="$store.state.darkMode"
            src="../assets/logo_skincasts_blanc.png"
            alt="skincasts"
            class="h-full w-full object-contain"
          />
        </a>
        <p
          class="  opacity-0"
          v-html="$t('sectionthree.text.skincasts')"
        ></p>
      </div>
      <div
        class=" p-4 flex flex-col justify-center items-center m-auto xl:w-10/12 xl:mx-4 my-4
        "
      >
        <h3 class="my-4 mt-16 text-xl opacity-0">
          <a href="https://www.hyvirea.fr/" target="_blank" rel="noopener" class="h-24 w-8/12 lg:w-6/12 sm:h-52 opacity-0">
            {{ $t("sectionthree.h3.hyvirea") }}
          </a>
        </h3>
        <a href="https://www.hyvirea.fr/" target="_blank" rel="noopener" class="w-8/12 lg:w-6/12 sm:h-52 opacity-0 text-blue-400 hover:text-black hover:underline">
          <img v-show="!$store.state.darkMode"
            src="../assets/logo_hyvirea.png"
            alt="Hyvirea"
            class="h-full w-full object-contain"
          />
          <img v-show="$store.state.darkMode == true"
            src="../assets/logo_hyvirea_blanc.png"
            alt="Hyvirea"
            class="h-full w-full object-contain"
          />
        </a>
        <p
          class="  opacity-0"
          v-html="$t('sectionthree.text.hyvirea')"
        ></p>
      </div>
    </div>
    <div
      id="seemore"
      class=" m-auto w-10/12 flex flex-col justify-center items-center mt-12 z-10 opacity-0
      "
    >
      <i class="fas fa-hand-point-down text-3xl animate-bounce"></i>
      <router-link @click="scrollTop" to="/projects" class="relative m-auto w-8/12 h-8 flex justify-center items-center xl:w-3/12 transition duration-300 group outline-none ">
        <div class="absolute bg-gradient-to-r from-blue-800 dark:from-pink-600 to-purple-900 -inset-0.5 blur rounded-full bg-btn opacity-40 group-hover:opacity-100 group-focus:opacity-100"></div>
        <p class="relative h-full w-full flex justify-center align-center bg-bg-hb text-white rounded-lg pt-0.5">{{ $t("sectionthree.text.projets") }}</p>
      </router-link>
    </div>
  </section>

</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "sectionone",
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

     

    return { t };
  },
  methods:{
    scrollTop(){
      window.scroll(0,0);
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-btn {
  animation: bgBtn 10s linear 0s infinite alternate-reverse both;
}
@keyframes bgBtn {
  0%, 50%, 100% {
    transform: rotate(1deg);
  }
  25%{
    transform: rotate(0deg);
  }
  75%{
    transform: rotate(-1deg);
  }
}
</style>

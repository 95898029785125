<template>
  <header
    id="top"
    class="bg-hb w-full h-5/6 flex justify-center items-center z-50"
  >
    <div class="flex justify-around items-center w-6/12 max-w-screen-sm">
    <router-link to="/" class="z-50">
      <h1><img
        src="../assets/logo_vrtigo_transparent.png"
        alt="VR'TIG.0"
        class="md:h-28 h-16 w-auto z-50"
      /></h1>
    </router-link>
    </div>
    <nav class="text-white hidden lg:contents text-xl">
      <router-link to="/" class="py-8 lg:mx-4 bg-active lg:relative router-link"
        >{{ $t("header.home") }} <i class="fas fa-home ml-2"></i
      ></router-link>
      <router-link to="/projects" class="py-8 lg:mx-4 bg-active lg:relative router-link"
        >{{ $t("header.projects") }} <i class="fab fa-unity ml-2"></i
      ></router-link>
    </nav>
    <NavMobile class="hidden" />
    <LocalSwitcher class="hidden lg:contents" />
    <div
      @click="showNavMobile"
      id="activeNavMobile"
      class="lg:hidden h-1 w-12 bg-white ml-24 relative"
    ></div>
  </header>
</template>
<script>
import { useI18n } from "vue-i18n";
import LocalSwitcher from "./LocalSwitcher.vue";
import NavMobile from "./NavMobile.vue";

export default {
  name: "header",
  components: {
    LocalSwitcher,
    NavMobile,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

     

    return { t };
  },
  methods: {
    showNavMobile() {
      const navMobile = document.querySelector("#navmobile");
      const icoNavMobile = document.querySelector('#activeNavMobile');
      icoNavMobile.classList.remove('animEnableNavMobile');
      icoNavMobile.classList.remove('animDisableNavMobile');

      if (navMobile.classList.contains("hidden")) {
        navMobile.classList.remove("hidden");
        navMobile.classList.add("transition_navmobile");
        icoNavMobile.classList.add('animEnableNavMobile');
      } else {
        navMobile.classList.remove("transition_navmobile");
        navMobile.classList.add("transitionOutNavMobile");
        icoNavMobile.classList.remove('animEnableNavMobile');
        icoNavMobile.classList.add('animDisableNavMobile');
        setTimeout(() => {
          navMobile.classList.add("hidden");
        }, 500);
      }
    },
  },
};
</script>
<style lang="scss">
#activeNavMobile {
  z-index: 100;
  &:after {
    content: "";
    position: absolute;
    bottom: 15px;
    background-color: white;
    z-index: 100;
    height: 100%;
    width: 100%;
    transform-origin: left;
  }
  &:before {
    content: "";
    position: absolute;
    top: 15px;
    background-color: white;
    z-index: 100;
    height: 100%;
    width: 100%;
    transform-origin: left;
  }
}
.animEnableNavMobile {
  background-color: #0b1c3a !important;
  transition: background-color 0.4s ease;
  &:after {
    transform: rotate(45deg) scaleX(90%);
    transition: transform ease 0.8s;
  }
  &:before {
    transform: rotate(-45deg) scaleX(90%);
    transition: transform ease 0.8s;
  }
}
.animDisableNavMobile {
  background-color: transparent;
  transition: background-color 0.6s ease;
  &:after {
    transform: rotate(0deg) scaleX(100%);
    transition: transform ease 0.8s;
  }
  &:before {
    transform: rotate(0deg) scaleX(100%);
    transition: transform ease 0.8s;
  }
}
.router-link {
  z-index: 10;
  &:after {
    content:'';
    position: absolute;
    bottom: 25px;left: 0;
    height: 2.5px;
    width: 0%;
    z-index: 1;
    transition: all 0.7s;
  }
  &:hover {
    &:after {
      background-color:white;
      width: 100%;
    }
  }
}
</style>
